import React from 'react'
import ComingSoon3 from './components/ComingSoon/ComingSoon3'

const App = () => {
  return (
   <>
   <ComingSoon3 />
   </>

  )
}
export default App;
